const THEME_KEY = 'theme';
const THEME_ATTRIBUTE = 'data-theme';

const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

const initThemeMode = () => {
  const theme = localStorage.getItem(THEME_KEY);
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const rootElement = document.documentElement;

  const setTheme = (updatedTheme) => {
    localStorage.setItem(THEME_KEY, updatedTheme);
    rootElement.setAttribute(THEME_ATTRIBUTE, updatedTheme);
  };

  if (!theme && prefersDarkMode) {
    setTheme(THEMES.DARK);
  } else if (theme && THEMES[theme.toUpperCase()]) {
    setTheme(theme);
  } else {
    setTheme(THEMES.LIGHT);
  }
};

initThemeMode();
